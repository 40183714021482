//Attention: can't be used as Regular component
// Usage: <Head>{Meta(metadata)}</Head>
// Reason: https://nextjs.org/docs/api-reference/next/head
import { useRouter } from 'next/router';

export const Meta = (meta, title?: string, ogType = 'website') => {
  const router = useRouter();
  const fullUrl = `${process.env.NEXT_PUBLIC_APP_URL ?? ''}${router.asPath}`;
  const fallbacks = {
    title: meta?.meta_title || 'TV-MEDIA – besser fernsehen und streamen.',
    description:
      meta?.meta_description ||
      'Österreichs bestes TV-Programm: Filme, Serien, Streaming, Kino, Stars sowie Infos und Empfehlungen der Redaktion.',
  };

  const ogTitle = meta?.custom_open_graph_title ?? title ?? fallbacks.title;
  const ogDesc =
    meta?.open_graph_description === 'custom_open_graph_description'
      ? meta.custom_open_graph_description
      : meta?.[meta.open_graph_description] || fallbacks.description;

  const twitterTitle = meta?.custom_twitter_title ?? title ?? fallbacks.title;
  const twitterDescription =
    meta?.twitter_description === 'custom_twitter_description'
      ? meta.custom_twitter_description
      : meta?.[meta.twitter_description] || fallbacks.description;

  const robotsContent = (meta?.no_index ? 'noindex,' : 'index,') + (meta?.no_follow ? 'nofollow' : 'follow');

  const getImageUrl = (path) => {
    return `/?url=${encodeURIComponent(path)}&width=1200&height=600&quality=75&format=webp`;
  };

  const twitterImagePath = getImageUrl(meta?.twitter_image ?? meta?.open_graph_image);
  const ogImagePath = getImageUrl(meta?.open_graph_image);

  return (
    <>
      <title>{title ?? fallbacks.title}</title>
      {meta?.google_news_title && <meta name="news:title" content={meta?.google_news_title} />}
      <meta name="description" content={fallbacks.description} />
      <meta name="twitter:url" content={fullUrl} />
      <meta name="twitter:title" content={twitterTitle} />
      <meta name="twitter:description" content={twitterDescription} />
      <meta name="twitter:image" content={twitterImagePath} />
      <meta property="og:type" content={ogType} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDesc} />
      <meta property="og:site_name" content="tv-media.at" />
      <meta property="og:url" content={fullUrl} />
      <meta property="og:image" content={ogImagePath} />
      <meta name="robots" content={robotsContent}></meta>
      {meta?.canonical_link && (
        <link rel="canonical" href={(process.env.NEXT_PUBLIC_APP_URL ?? '') + meta?.canonical_link} />
      )}
      {meta?.redirect && <meta httpEquiv="refresh" content={`0; url=${meta.redirect}`} />}
      <meta name="application-name" content="tv-media.at" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-title" content="tv-media.at" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="theme-color" content="#000000" />
      <meta charSet="utf-8" />
      <link rel="manifest" href="/manifest.json" />
      <link rel="shortcut icon" type="image/x-icon" href="/assets/icons/favicon.ico" />
      <link rel="apple-touch-icon" href="/assets/icons/apple-touch/touch-icon-iphone-23311f27.png" />
      <link rel="apple-touch-icon" sizes="76x76" href="/assets/icons/apple-touch/touch-icon-ipad-23311f27.png" />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="/assets/icons/apple-touch/touch-icon-iphone-retina-70bfbf42.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/assets/icons/apple-touch/touch-icon-ipad-retina-11a251a0.png"
      />
    </>
  );
};
