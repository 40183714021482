import React, { PropsWithChildren, useEffect, useState } from 'react';
import { composeStyles, PropsWithClassProps, twMerge } from '@vgn-medien-holding/vgn-fe-components';
import { useSession } from 'next-auth/react';
import { Button } from '@components/atoms/Button/Button';
import { Checkbox } from '@components/atoms/Checkbox/Checkbox';
import { IconBookmark } from '@src/assets/icon-bookmark';
import { TvmFavoriteEntity, UserSettingEntryType } from '@src/lib/graphql/generated';
import { useFavoritesStore } from '@src/stores/favorites';
import { useLoginStore } from '@src/stores/login';
import { Dialog } from '../Dialog/Dialog';

export interface FavoriteButtonProps
  extends PropsWithChildren,
    PropsWithClassProps<'bookmarked' | 'icon' | 'iconWrapper' | 'bookmarkedIconWrapper'> {
  id: string;
  type: TvmFavoriteEntity['__typename'];
  onlyShowFavorite?: boolean;
  loginFlowTitle?: string;
  askBeforeRemove?: boolean;
}

export const FavoriteButton = ({
  id,
  type,
  onlyShowFavorite,
  loginFlowTitle,
  askBeforeRemove,
  classProps,
  children,
}: FavoriteButtonProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [noConfirmationChecked, setNoConfirmationChecked] = useState(false);

  const { updateLoginStore, getUserSetting, setUserSetting } = useLoginStore((state) => ({
    updateLoginStore: state.updateLoginStore,
    getUserSetting: state.getUserSetting,
    setUserSetting: state.setUserSetting,
  }));
  const { status } = useSession();
  const { getFavoriteState, toggleFavorite } = useFavoritesStore((state) => ({
    getFavoriteState: state.getFavoriteState,
    toggleFavorite: state.toggleFavorite,
  }));

  useEffect(() => {
    if (askBeforeRemove && !getUserSetting('no_confirmation_on_favorite_remove')) {
      import('../Dialog/Dialog');
    }
  }, [askBeforeRemove]);

  if (onlyShowFavorite && !getFavoriteState(id)) return null;

  const defaultStyle = {
    root: 'flex items-center gap-4 canhover:hover:text-primary group/favbutton',
    bookmarked: 'text-primary',
    iconWrapper:
      'size-8 flex-none grid place-items-center rounded-full border border-gray-650 canhover:group-hover/favbutton:border-primary transition-colors duration-200',
    bookmarkedIconWrapper: 'border-primary',
    icon: 'size-4',
  };

  const styles = composeStyles(defaultStyle, classProps);

  return (
    <>
      <button
        className={twMerge(styles.root, getFavoriteState(id) && styles.bookmarked)}
        onClick={async (evt) => {
          evt.preventDefault();
          evt.stopPropagation();
          if (status === 'authenticated') {
            if (askBeforeRemove && !getUserSetting('no_confirmation_on_favorite_remove') && getFavoriteState(id)) {
              return setDialogOpen(true);
            }
            toggleFavorite({ id, type });
          } else {
            toggleFavorite({ id, type, isTemp: true });
            const toast = (await import('react-toastify')).toast;
            toast.info(
              <div>
                <p className="font-bold">Eintrag zwischengespeichert</p>
                <div className="mt-2 text-gray-400">Melde dich an um zu speichern</div>
              </div>,
            );
            updateLoginStore({
              loginFlowOpen: true,
              loginFlowTitle: loginFlowTitle ?? 'Speichere jetzt Deine Lieblingsserien und -filme',
            });
          }
        }}
      >
        <div className={twMerge(styles.iconWrapper, getFavoriteState(id) && styles.bookmarkedIconWrapper)}>
          <IconBookmark classProps={{ root: styles.icon }} filled={getFavoriteState(id, status !== 'authenticated')} />
        </div>
        {!!children && <div>{children}</div>}
      </button>
      <div className="absolute">
        {askBeforeRemove && !getUserSetting('no_confirmation_on_favorite_remove') && (
          <Dialog
            title="Bist du Dir sicher?"
            description="Der Inhalt wird von Deiner Watchlist entfernt."
            isOpen={dialogOpen}
            onClose={() => setDialogOpen(false)}
          >
            <div className="mt-4 grid gap-4">
              <Button
                hasAction
                buttonStyle="primary"
                title="Ja, bitte entfernen"
                onAction={() => {
                  toggleFavorite({ id, type });

                  if (noConfirmationChecked) {
                    setUserSetting({
                      type: UserSettingEntryType.Boolean,
                      key: 'no_confirmation_on_favorite_remove',
                      value: 'true',
                    });
                  }
                  setDialogOpen(false);
                }}
                preventDefault
                stopPropagation
              />
              <Button
                hasAction
                title="Nein, abbrechen"
                onAction={() => setDialogOpen(false)}
                preventDefault
                stopPropagation
              />
              <Checkbox
                id="no_confirmation_on_favorite_remove"
                label="Bitte nicht mehr nachfragen"
                classProps={{ root: 'justify-center mt-6' }}
                checkboxProps={{
                  onChange: (e) => {
                    setNoConfirmationChecked(e.target.checked);
                  },
                }}
              />
            </div>
          </Dialog>
        )}
      </div>
    </>
  );
};
