import React from 'react';
import {
  Checkbox as DefaultCheckbox,
  CheckboxProps as DefaultCheckboxProps,
  composeStyles,
} from '@vgn-medien-holding/vgn-fe-components';
import checkboxStyle from './styles/default';

export const Checkbox = ({ id, label, checkboxProps, classProps }: DefaultCheckboxProps) => {
  const styles = composeStyles(checkboxStyle, classProps);
  return <DefaultCheckbox id={id} label={label} classProps={styles} checkboxProps={checkboxProps} />;
};
